import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { items } from "@/api/items/useItemQuery";
import { mutateLots } from "@/api/lots/useLotsQuery";
import { purchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { LotMutationFromProps } from "@/features/stockPlan/form/stockPlanIncomingForm";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { LotSelectComplete } from "@/features/ui/autoComplete/lot/lot-\bselectComplete";
import useGetLotsIdQuery from "@/features/ui/autoComplete/lot/select-useGetLotsQuery";
import { LotForm } from "@/features/ui/form/lots/form";
import styled from "@emotion/styled";
import { Button, Flex, Radio, Space, Table, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { DefaultApiLotsPostRequest, DefaultApiWmsIncomingPostRequest, PurchaseOrdersGet200ResponseRowsInner, WorkLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { EventHandler, useState } from "react";

interface PurchaseOrderItemsSelectFormProps {
    formatterProps: PurchaseOrdersGet200ResponseRowsInner
}

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Date: DateInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    ItemAutoComplete: ItemAutoComplete,
});

export const OrderItemsIncomingForm = (params: PurchaseOrderItemsSelectFormProps) => {
    const { formatterProps } = params;
    const { closeModal, openModal } = useModal();
    const [selectedRadio, setSelectedRadio] = useState(null);

    const queryClient = useQueryClient();
    const { mutate: incomingMutate } = useMutation(
        (params: DefaultApiWmsIncomingPostRequest) =>
            mutateInventories.incoming(params).mutationFn(params as DefaultApiWmsIncomingPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["inventories"]);
            }
        }
    )

    const { mutate: postLotsMutate } = useMutation(
        (params: DefaultApiLotsPostRequest) =>
            mutateLots.create(params).mutationFn(params as DefaultApiLotsPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["lots"]);
            }
        }
    );

    const { data: purchaseItemData } = useQuery({
        ...purchaseOrderItems.detail({
            purchaseOrderItemId: selectedRadio ?? 0,
        }),
        enabled: !!selectedRadio,
    })

    const [newLot, setNewLot] = useState<LotMutationFromProps>();

    const form = useForm<Partial<WorkLogsWorkLogIdCancelEndPut200ResponseCancelProductionInner>>({
        initialValues: {
            itemCode: purchaseItemData?.data?.itemCode,
            locationCode: undefined,
            quantity: undefined,
            lotId: null,
            lotInfo: {
                name: null,
                expiration: null,
            },
            purchaseOrderItemId: purchaseItemData?.data?.id,
        },
    });

    const { data: itemData } = useQuery({
        ...items.codeSearch({
            query: purchaseItemData?.data?.itemCode
                ? { $and: [{ code: { $eq: purchaseItemData?.data?.itemCode } }] }
                : "",
        }),
        enabled: !!purchaseItemData?.data?.itemCode
    });

    const { data: lotData } = useGetLotsIdQuery(
        Number(form.values?.lotId) || Number(newLot?.id),
        !!form.values?.lotId || !!newLot?.id
    );

    const handleRadioChange = (id: number | any) => {
        setSelectedRadio(id);
    };
   
    const onSubmit = () => {
        try {
            const { itemCode, locationCode, quantity } = form.values;
            if (!itemCode && !locationCode && !quantity) return;

            incomingMutate(
                {
                    workLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner: {
                        itemCode:
                            purchaseItemData?.data?.itemCode ?? '',
                        locationCode:
                            form.values.locationCode as string,
                        quantity: form.values.quantity as string,
                        lotId: Number(newLot?.id ?? form.values.lotId) ?? null,
                        lotInfo: {
                            name:
                                lotData?.name ?? newLot?.name ?? form?.values?.lotInfo?.name,
                            expiration:
                                lotData?.expiration ?? newLot?.expiredAt ?? form?.values?.lotInfo?.expiration,
                        },
                        actionFrom: 'WEB',
                        purchaseOrderItemId: purchaseItemData?.data?.id,
                    },
                },
                {
                    onSuccess: (res: { status: number }) => {
                        closeModal(form.values);
                        if (res.status === 200) {
                            customAlert(
                                "입고에 성공하였습니다.",
                                "입고 성공",
                                "green"
                            )
                        } else {
                            customAlert(
                                "입고에 실패하였습니다.",
                                "입고 실패",
                                "red"
                            )
                        }
                    },
                }
            );
        } catch (e) {
            customAlert(
                "입고에 실패하였습니다.",
                "입고 실패",
                "red"
            )
        }
    };

    const onCloseHandler: EventHandler<any> = (values) => {
        postLotsMutate(
            {
                lotsGetRequest: {
                    name: values.name!,
                    expiration: values.expiredAt!,
                    itemCode: values.itemCode!,
                },
            },
            {
                onSuccess: (data) => {
                    setNewLot(data?.data as LotMutationFromProps | undefined);
                    form.setFieldValue("lotId", data?.data?.id);
                    form.setFieldValue("lotInfo.name", data?.data?.name);
                    form.setFieldValue("lotInfo.expiration", data?.data?.expiration);
                    alert("로트를 생성했습니다.");
                },
            }
        );
        closeModal(values);
    };

    const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
        e
    ) => {
        e.preventDefault();
        openModal(
            <LotForm
                itemCodeValue={purchaseItemData?.data?.itemCode as string}
                onSubmit={onCloseHandler}
            />,
            null,
            "신규 LOT 등록",
            true
        ).then((result) => { });
    };

    return (
        <>
            <Wrapper>
                {formatterProps.purchaseOrderItems && formatterProps.purchaseOrderItems.length > 0 && (
                    formatterProps.purchaseOrderItems.map((items) => (
                        <Table key={items.id} withBorder withColumnBorders w="40rem">
                            <thead>
                                <Tr>
                                    <Th flex={0.5} className="small-column"></Th>
                                    <Th flex={2}>협력사명</Th>
                                    <Th flex={2}>품목명</Th>
                                    <Th flex={1}>수량</Th>
                                    <Th flex={2}>로케이션</Th>
                                </Tr>
                            </thead>
                            <tbody>
                                <Tr>
                                    <Td flex={0.5} className="small-column">
                                        <Flex
                                            align="center"
                                            justify="center"
                                        >
                                            <Radio
                                                value={items.id}
                                                checked={selectedRadio === items.id}
                                                onChange={() => handleRadioChange(items.id)}
                                            />
                                        </Flex>
                                    </Td>
                                    <Td flex={2}>{items.partnerCode}</Td>
                                    <Td flex={2}>{items.itemCode}</Td>
                                    <Td flex={1} className="textRight">{items.quantity}</Td>
                                    <Td flex={2}>{items.targetLocationCode}</Td>
                                </Tr>
                            </tbody>
                        </Table>
                    ))
                )}
                {formatterProps.purchaseOrderItems && formatterProps.purchaseOrderItems.length === 0 ? (
                    <Flex>
                        <Text size="xl" fw="bold">
                            발주항목이 없습니다. 발주항목을 생성해주세요.
                        </Text>
                    </Flex>
                ) : (
                    <>
                        <Create.Input
                            disabled
                            label="품목코드"
                            withAsterisk
                            {...form.getInputProps("itemCode")}
                            defaultValue={purchaseItemData?.data?.itemCode}
                        />
                        <Flex justify={"space-between"} align={'center'} w="100%">
                            <LotSelectComplete
                                w="100%"
                                label="LOT 정보"
                                description={`LOT 관리 기준 : 
                                    ${form.values.itemCode &&
                                        itemData?.data?.rows &&
                                        itemData?.data?.rows[0]?.usesLotName &&
                                        itemData?.data?.rows[0]?.usesLotExpiration
                                        ? "이름 + 만료일"
                                        : form.values.itemCode &&
                                            itemData?.data?.rows &&
                                            itemData?.data?.rows[0]?.usesLotName
                                            ? "이름"
                                            : form.values.itemCode &&
                                                itemData?.data?.rows &&
                                                itemData?.data?.rows[0]?.usesLotExpiration
                                                ? "만료일"
                                                : "관리 안함"
                                    }`}
                                itemCodeValue={
                                    (form.values.itemCode as string) || (lotData?.itemCode as string)
                                }
                                withAsterisk
                                newIdValue={lotData?.id}
                                dropdownPosition="bottom"
                                {...form.getInputProps("lotId")}
                                defaultValue={lotData && lotData?.id?.toString()}
                                mr="sm"
                                styles={
                                    lotData
                                        ? {
                                            description: { fontSize: 10, color: "red" },
                                            input: { fontSize: "0.8em", paddingBottom: "0.5rem" },
                                        }
                                        : {
                                            description: { fontSize: 10, color: "red" },
                                        }
                                }
                                disabled={false}
                            />
                            <div>
                                <Space h="md" />
                                <Space h="xl" />
                                <Button
                                    my="xl"
                                    disabled={
                                        (itemData?.data?.rows &&
                                            itemData?.data?.rows[0]?.usesLotName) ||
                                            (itemData?.data?.rows &&
                                                itemData?.data?.rows[0]?.usesLotExpiration)
                                            ? false
                                            : true
                                    }
                                    onClick={addNewModalHandler}
                                >
                                    신규로트 생성
                                </Button>
                            </div>
                        </Flex>
                        <LocationAutoComplete
                            label="로케이션코드"
                            withAsterisk
                            {...form.getInputProps("locationCode")}
                        />
                        <Create.Input
                            label="수량"
                            withAsterisk
                            type="number"
                            {...form.getInputProps("quantity")}
                        />
                        <Create.Input
                            disabled
                            label="만료일"
                            {...form.getInputProps("lotInfo.expiration")}
                        />
                        <Create.ButtonBox>
                            <Create.Button color="gray" onClick={closeModal}>
                                취소
                            </Create.Button>
                            <Create.Button onClick={onSubmit} disabled={!selectedRadio}>입고</Create.Button>
                        </Create.ButtonBox>
                    </>
                )}
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const Th = styled.th<{ flex: number }>`
    padding: 0.5rem;
    text-align: center;
    flex : ${(props) => (props.flex ? props.flex : 1)};
`
const Tr = styled.tr`
    display: flex;
    flex-direction: row;
`

const Td = styled.td<{ flex: number }>`
    padding: 0.5rem;
    text-align: center;
    flex : ${(props) => (props.flex ? props.flex : 1)};

    &.textRight {
        text-align: right;
    }
`