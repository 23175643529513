import DefaultInstance from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiDefectsDefectCodeDeleteRequest, DefaultApiDefectsDefectCodePutRequest, DefaultApiDefectsFindPostRequest, DefaultApiDefectsGetRequest, DefaultApiDefectsPostRequest, DefectsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";


export const defects = createQueryKeys('defects', {
    all: null,
    get: (params: DefaultApiDefectsGetRequest) => {
        // const queryParams = { ...params };
        const queryParams = { ...params, query: JSON.stringify({ "$and": params?.query }) };

        return {
            queryKey: [queryParams, JSON.stringify(queryParams)],
            queryFn: () => DefaultInstance.defectsGet(queryParams),
        }
    },
    find: (params: DefaultApiDefectsFindPostRequest) => {
        const query = { ...params };
        if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => DefaultInstance.defectsFindPost(query)
        }
    },
    findSelect: (params: DefaultApiDefectsFindPostRequest) => {
        return {
            queryKey: [params],
            queryFn: () => DefaultInstance.defectsFindPost().then((res: AxiosResponse<DefectsGet200ResponseRowsInner[]>) => res.data),

        }
    }
});

export const mutateDefect = createMutationKeys('defects', {
    create: (params: DefaultApiDefectsPostRequest) => ({
        mutationKey: [params.defectsGetRequest],
        mutationFn: () => DefaultInstance.defectsPost(params),
    }),
    update: (params: DefaultApiDefectsDefectCodePutRequest) => ({
        mutationKey: [params.defectCode, params.defectsGetRequest],
        mutationFn: () => DefaultInstance.defectsDefectCodePut(params)
    }),
    delete: (params: DefaultApiDefectsDefectCodeDeleteRequest) => ({
        mutationKey: [params.defectCode],
        mutationFn: () => DefaultInstance.defectsDefectCodeDelete(params)
    }),
});

export const defectQueryKeys = mergeQueryKeys(defects, mutateDefect);
